import { useRuntimeConfig } from '#app'
const { useCustomFetch } = useFetchBase()

// request(REQ) Api  --------------------------------
const base = '/api/message'
const modules = '/conversations'
const path = base + modules

const getConListREQ = path + '?status=assigned' // 獲取個人，在線會話列表

const getConHistoryREQ = path + '/customers/' // (座席)獲取訪客會話歷史訊息
const finishConREQ = path + '/{0}/finish' // 座席結束對話
const getConAllInfo = path + '/{0}/details' // 獲取會話 (含訊息、客戶資訊、客戶歷史會話)
const getConInfo = path + '/{0}/info' // 獲取會話 (含訊息)
const getChatHistoryMsg = path + '/{0}/messages' // 獲取會話歷史訊息
const getConOnlineInfo = path + '/{0}/online' // 獲取在線會話 (含顧客歷史訊息、渠道信息、客戶資訊)
const inviteSatisfaction = path + '/{0}/invite/rating' // 邀請訪客評價

const addConversationTags = path + '/{0}/tags' // 添加對話標籤

const finshConvWithBlock = path + '/{0}/finishWithBlock'

const transferTo = path + '/{0}/transfer' // 轉接會話
const agreeTransfer = path + '/{0}/transfer/agree' // 同意轉接
const refuseTransfer = path + '/{0}/transfer/refuse' // 拒絕轉接

// request(REQ) Function  ---------------------------

/**
 * 獲取個人，在線會話列表
 * @param {Object} callback 回調函數
 * @returns
 */
const getConListFn = (callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + getConListREQ, {
    method: 'GET',
    body: null,
    onResponse: callback
  })
}

/**
 * (座席)獲取訪客會話歷史訊息
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
const getConHistoryFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(
    apiBaseUrl + getConHistoryREQ + params.customersId + '/messages?channelId=' + params.channelId + '&pageNum=' + params.pageNum + '&pageSize=' + params.pageSize
    , {
      method: 'GET',
      body: null,
      onResponse: callback
    })
}

/**
 * 座席結束對話
 * @param {String} id 對話id
 * @returns
 */
const finishConFn = (id) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(finishConREQ, id)
  return useCustomFetch(api, {
    method: 'PUT'
  })
}
/**
 * 提交黑名單並結束對話
 * @param {String} id 會話id
 * @param {Object} formData 表單提交 (黑名單參數)
 * @param {Object} callback 回調函數
 * @returns
 */
const finshConvWithBlockFn = (id, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(finshConvWithBlock, id)
  return useCustomFetch(api, {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 獲取會話 (含訊息、客戶資訊、客戶歷史會話)
 * @param {String} conId 對話id
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns 取得此訪客所有對話紀錄+當筆資訊
 */
const getConAllInfoFn = (conId, params, callback) => { // 取得此訪客所有對話紀錄+當筆資訊
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(getConAllInfo, conId) + '?pageNum=' + params.pageNum + '&pageSize=' + params.pageSize
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取會話(含訊息)
 * @param {String} conId 對話id
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns  取得點擊的當筆對話紀錄+資訊
 */
const getOtherConInfoFn = (conId, params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(getConInfo, conId) + '?pageNum=' + params.pageNum + '&pageSize=' + params.pageSize
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取會話歷史訊息
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
const getChatHistoryMsgFn = (conId, params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(
    apiBaseUrl + stringFormat(getChatHistoryMsg, conId) + combineUrlWithParams('', params), {
      method: 'GET',
      onResponse: callback
    })
}
/**
 * 獲取在線會話 (含顧客歷史訊息、渠道信息、客戶資訊)
 * @param {String} conId 對話id
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
const getConOnlineInfoFn = (conId, params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(getConOnlineInfo, conId) + '?pageNum=' + params.pageNum + '&pageSize=' + params.pageSize
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 邀請訪客評價
 * @param {String} conId 對話id
 * @param {Object} callback 回調函數
 * @returns
 */
const inviteSatisfactionFn = (conId, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(inviteSatisfaction, conId)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 新增會話標籤至會話
 * @param {String} id 會話id
 * @param {Array<Long>} formData 表單資料
 * @param {Object} callback 回調函數
 * @returns
 */
const addConversationTagsFn = (id, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(addConversationTags, id)
  return useCustomFetch(api, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 轉接會話
 * @param {String} conId 對話id
 * @param {Object} formData 提交表單
 * @param {Object} callback 回調函數
 * @returns
 */
const transferToFn = (conId, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(transferTo, conId)
  return useCustomFetch(api, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 同意轉接
 * @param {String} conId 對話id
 * @param {Object} callback 回調函數
 * @returns
 */
const agreeTransferFn = (conId, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(agreeTransfer, conId)
  return useCustomFetch(api, {
    method: 'POST',
    onResponse: callback
  })
}

/**
 * 拒絕轉接
 * @param {String} conId 對話id
 * @param {Object} callback 回調函數
 * @returns
 */
const refuseTransferFn = (conId, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(refuseTransfer, conId)
  return useCustomFetch(api, {
    method: 'POST',
    onResponse: callback
  })
}

export {
  getConListFn,
  getConHistoryFn,
  finishConFn,
  finshConvWithBlockFn,
  getConAllInfoFn,
  getOtherConInfoFn,
  getChatHistoryMsgFn,
  getConOnlineInfoFn,
  inviteSatisfactionFn,
  addConversationTagsFn,
  transferToFn,
  agreeTransferFn,
  refuseTransferFn
}
